import api from "@modules/api/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import purchaseQueryKeys from "../purchaseQueryKeys";
import {
  ExtendedWarrantyRatesRequest,
  ExtendedWarrantyRatesResponse,
} from "../types/ExtendedWarrantyRates";

import validateRequiredRatesCallFields from "../helpers/validateRequiredRatesCallFields";
import { getCoveragesError } from "../helpers/getCoveragesError";

type ExtendedWarrantyRatesRequestError = {
  success: false;
  message: string;
};

const getExtendedWarrantyRates = async (
  params: ExtendedWarrantyRatesRequest,
  locale: string
) => {
  const queryString = Object.entries(params)
    .filter(([, value]) => value != null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`
    )
    .join("&");

  const response = await api.get<
    ExtendedWarrantyRatesResponse | ExtendedWarrantyRatesRequestError
  >(`api/purchases/ext-warranty-default-rates?${queryString}`, {
    headers: { "Accept-Language": locale },
  });

  if (response.success === false) {
    throw new Error(response.message);
  }

  return response;
};

export default function useExtendedWarrantyDefaultRates(
  params: ExtendedWarrantyRatesRequest,
  isProvinceActive: boolean | null,
  locale: string,
  enabled = true
) {
  const areRequiredFieldsAvailable = validateRequiredRatesCallFields({
    vin: params.vin,
    year: params.year,
    make: params.make,
    model: params.model,
    odometer: params.odometer,
  });

  // Convert the source to lowercase because it originates from either dealSource or the query string, where differences in casing can lead to key changes, triggering a re-query.
  const queryParams = { ...params, source: params.source?.toLowerCase() ?? "" };

  const query = useQuery({
    queryKey: purchaseQueryKeys.extendedWarrantyDefaultRates(
      queryParams,
      locale
    ),
    queryFn: () => getExtendedWarrantyRates(queryParams, locale),
    placeholderData: keepPreviousData,
    enabled:
      enabled &&
      areRequiredFieldsAvailable &&
      locale !== undefined &&
      (isProvinceActive === true || isProvinceActive == null),
    staleTime: 60 * 60 * 1000, // 60 minutes
  });

  return {
    ...query,
    coverageError: query.isError ? getCoveragesError(query.error) : null,
  };
}
