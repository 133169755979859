import { TranslationKeys } from "src/@types/i18next";
import {
  ComponentAdditionalVehicleIcon,
  ComponentAirConditioningIcon,
  ComponentBrakesIcon,
  ComponentCoolingSystemIcon,
  ComponentDriveAxleIcon,
  ComponentElectricalIcon,
  ComponentEngineIcon,
  ComponentEnhancedElectricalIcon,
  ComponentExtraValueIcon,
  ComponentFuelSystemIcon,
  ComponentSealsAndGasketsIcon,
  ComponentSteeringIcon,
  ComponentSuspensionIcon,
  ComponentTransferCaseIcon,
  ComponentTransmissionIcon,
  ComponentTurbochargerIcon,
} from "@common/components/icons";
import React from "react";
import { EW_ADDITIONAL_VEHICLE_COMPONENTS } from "@common/constants";
import {
  ExtendedWarrantyAmaRightRideDigital,
  ExtendedWarrantyEssentialHMRightRideDigital,
  ExtendedWarrantyEssentialVARightRideDigital,
  ExtendedWarrantyPremiumRightRideDigital,
} from "./IAProgram";
import {
  IACoverageType,
  superiorCoverage,
} from "./ExtendedWarrantyCoverageType";

type WarrantyComponent = {
  id: string;
  name: TranslationKeys["purchase"] | TranslationKeys["common"];
  coverages: string[];
  details: {
    i18nkey: TranslationKeys["purchase"];
    coverageSpecificComponents?: {
      coverageType: IACoverageType;
      i18nkey: TranslationKeys["purchase"];
    };
  };
  icon?: JSX.Element;
};

type WarrantyComponentsByProgram = {
  [programId: string]: WarrantyComponent[];
};

const additionalVehicleIcon = React.createElement(
  ComponentAdditionalVehicleIcon,
  {
    className: "block text-blueZodiac m-auto w-6 h-6",
  }
);

const airConditioningIcon = React.createElement(ComponentAirConditioningIcon, {
  className: "block text-blueZodiac m-auto w-6 h-6",
});

const brakesIcon = React.createElement(ComponentBrakesIcon, {
  className: "block text-blueZodiac m-auto w-6 h-6",
});

const coolingSystemIcon = React.createElement(ComponentCoolingSystemIcon, {
  className: "block text-blueZodiac m-auto w-6 h-6",
});

const driveAxleIcon = React.createElement(ComponentDriveAxleIcon, {
  className: "block text-blueZodiac m-auto w-6 h-6",
});

const electricalIcon = React.createElement(ComponentElectricalIcon, {
  className: "block text-blueZodiac m-auto w-6 h-6",
});

const engineIcon = React.createElement(ComponentEngineIcon, {
  className: "block text-blueZodiac m-auto w-6 h-6",
});

const enhancedElectricalIcon = React.createElement(
  ComponentEnhancedElectricalIcon,
  {
    className: "block text-blueZodiac m-auto w-6 h-6",
  }
);

const extraValueIcon = React.createElement(ComponentExtraValueIcon, {
  className: "block text-blueZodiac m-auto w-6 h-6",
});

const fuelSystemIcon = React.createElement(ComponentFuelSystemIcon, {
  className: "block text-blueZodiac m-auto w-6 h-6",
});

const sealsAndGasketsIcon = React.createElement(ComponentSealsAndGasketsIcon, {
  className: "block text-blueZodiac m-auto w-6 h-6",
});

const steeringIcon = React.createElement(ComponentSteeringIcon, {
  className: "block text-blueZodiac m-auto w-6 h-6",
});

const suspensionIcon = React.createElement(ComponentSuspensionIcon, {
  className: "block text-blueZodiac m-auto w-6 h-6",
});

const transferCaseIcon = React.createElement(ComponentTransferCaseIcon, {
  className: "block text-blueZodiac m-auto w-6 h-6",
});

const transmissionIcon = React.createElement(ComponentTransmissionIcon, {
  className: "block text-blueZodiac m-auto w-6 h-6",
});

const turbochargerIcon = React.createElement(ComponentTurbochargerIcon, {
  className: "block text-blueZodiac m-auto w-6 h-6",
});

const warrantyComponentsByProgram: WarrantyComponentsByProgram = {
  [ExtendedWarrantyPremiumRightRideDigital.id]: [
    {
      id: "engine",
      name: "purchase:engine",
      coverages: ["BA", "PR", "CL", "SU", "SW"],
      details: {
        i18nkey: "purchase:premium_engine_components",
        coverageSpecificComponents: {
          coverageType: superiorCoverage,
          i18nkey: "purchase:premium_engine_superior_only",
        },
      },
      icon: engineIcon,
    },
    {
      id: "turbocharger",
      name: "purchase:turbocharger",
      coverages: ["BA", "PR", "CL", "SU", "SW"],
      details: {
        i18nkey: "purchase:premium_turbocharger_components",
      },
      icon: turbochargerIcon,
    },
    {
      id: "transmission",
      name: "common:transmission",
      coverages: ["BA", "PR", "CL", "SU", "SW"],
      details: {
        i18nkey: "purchase:premium_transmission_components",
        coverageSpecificComponents: {
          coverageType: superiorCoverage,
          i18nkey: "purchase:premium_transmission_superior_only",
        },
      },
      icon: transmissionIcon,
    },
    {
      id: "transferCase",
      name: "purchase:transfer_case",
      coverages: ["BA", "PR", "CL", "SU", "SW"],
      details: {
        i18nkey: "purchase:premium_transfer_case_components",
        coverageSpecificComponents: {
          coverageType: superiorCoverage,
          i18nkey: "purchase:premium_transfer_case_superior_only",
        },
      },
      icon: transferCaseIcon,
    },
    {
      id: "driveAxle",
      name: "purchase:drive_axle",
      coverages: ["BA", "PR", "CL", "SU", "SW"],
      details: {
        i18nkey: "purchase:premium_drive_axle_components",
      },
      icon: driveAxleIcon,
    },
    {
      id: "steering",
      name: "purchase:steering",
      coverages: ["PR", "CL", "SU", "SW"],
      details: {
        i18nkey: "purchase:premium_steering_components",
      },
      icon: steeringIcon,
    },
    {
      id: "brakes",
      name: "purchase:brakes",
      coverages: ["PR", "CL", "SU", "SW"],
      details: {
        i18nkey: "purchase:premium_brakes_components",
        coverageSpecificComponents: {
          coverageType: superiorCoverage,
          i18nkey: "purchase:premium_brakes_superior_only",
        },
      },
      icon: brakesIcon,
    },
    {
      id: "airConditioner",
      name: "purchase:air_conditioner",
      coverages: ["PR", "CL", "SU", "SW"],
      details: {
        i18nkey: "purchase:premium_ac_components",
      },
      icon: airConditioningIcon,
    },
    {
      id: "fuelSystem",
      name: "purchase:fuel_system",
      coverages: ["PR", "CL", "SU", "SW"],
      details: {
        i18nkey: "purchase:premium_fuel_system_components",
      },
      icon: fuelSystemIcon,
    },
    {
      id: "electrical",
      name: "purchase:electrical",
      coverages: ["PR", "CL", "SU", "SW"],
      details: {
        i18nkey: "purchase:premium_electrical_components",
        coverageSpecificComponents: {
          coverageType: superiorCoverage,
          i18nkey: "purchase:premium_electrical_superior_only",
        },
      },
      icon: electricalIcon,
    },
    {
      id: "enhancedElectrical",
      name: "purchase:enhanced_electrical",
      coverages: ["CL", "SU", "SW"],
      details: {
        i18nkey: "purchase:premium_enhanced_electrical_components",
      },
      icon: enhancedElectricalIcon,
    },
    {
      id: "frontRearSuspensions",
      name: "purchase:front_rear_suspensions",
      coverages: ["CL", "SU", "SW"],
      details: {
        i18nkey: "purchase:premium_front_rear_suspension_components",
      },
      icon: suspensionIcon,
    },
    {
      id: "coolingSystem",
      name: "purchase:cooling_system",
      coverages: ["CL", "SU", "SW"],
      details: {
        i18nkey: "purchase:premium_cooling_components",
      },
      icon: coolingSystemIcon,
    },
    {
      id: EW_ADDITIONAL_VEHICLE_COMPONENTS,
      name: "purchase:additional_vehicle_components",
      coverages: ["SU", "SW"],
      details: {
        i18nkey: "purchase:premium_additional_vehicle_components",
      },
      icon: additionalVehicleIcon,
    },
  ],
  [ExtendedWarrantyEssentialVARightRideDigital.id]: [
    {
      id: "engine",
      name: "purchase:engine",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_engine_components",
      },
      icon: engineIcon,
    },
    {
      id: "turbocharger",
      name: "purchase:turbocharger",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_turbocharger_components",
      },
      icon: turbochargerIcon,
    },
    {
      id: "transmission",
      name: "common:transmission",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_transmission_components",
      },
      icon: transmissionIcon,
    },
    {
      id: "transferCase",
      name: "purchase:transfer_case",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_transfer_case_components",
      },
      icon: transferCaseIcon,
    },
    {
      id: "driveAxle",
      name: "purchase:drive_axle",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_drive_axle_components",
      },
      icon: additionalVehicleIcon,
    },
    {
      id: "steering",
      name: "purchase:steering",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_steering_components",
      },
      icon: steeringIcon,
    },
    {
      id: "brakes",
      name: "purchase:brakes",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_brakes_components",
      },
      icon: brakesIcon,
    },
    {
      id: "electrical",
      name: "purchase:electrical",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_electrical_components",
      },
      icon: electricalIcon,
    },
    {
      id: "fuelSystem",
      name: "purchase:fuel_system",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_fuel_system_components",
      },
      icon: fuelSystemIcon,
    },
    {
      id: "frontSuspension",
      name: "purchase:front_suspension",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_front_suspension_components",
      },
      icon: suspensionIcon,
    },
    {
      id: "sealsAndGaskets",
      name: "purchase:seals_and_gaskets",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_seals_and_gaskets_components",
      },
      icon: sealsAndGasketsIcon,
    },
    {
      id: "airConditioner",
      name: "purchase:air_conditioner",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_air_conditioner_components",
      },
      icon: airConditioningIcon,
    },
    {
      id: "extraValueProtection",
      name: "purchase:extra_value_protection",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_extra_value_protection_components",
      },
      icon: extraValueIcon,
    },
  ],
  [ExtendedWarrantyEssentialHMRightRideDigital.id]: [
    {
      id: "engine",
      name: "purchase:engine",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_engine_components",
      },
      icon: engineIcon,
    },
    {
      id: "turbocharger",
      name: "purchase:turbocharger",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_turbocharger_components",
      },
      icon: turbochargerIcon,
    },
    {
      id: "transmission",
      name: "common:transmission",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_transmission_components",
      },
      icon: transmissionIcon,
    },
    {
      id: "transferCase",
      name: "purchase:transfer_case",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_transfer_case_components",
      },
      icon: transferCaseIcon,
    },
    {
      id: "driveAxle",
      name: "purchase:drive_axle",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_drive_axle_components",
      },
      icon: driveAxleIcon,
    },
    {
      id: "steering",
      name: "purchase:steering",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_steering_components",
      },
      icon: steeringIcon,
    },
    {
      id: "brakes",
      name: "purchase:brakes",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_brakes_components",
      },
      icon: brakesIcon,
    },
    {
      id: "electrical",
      name: "purchase:electrical",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_electrical_components",
      },
      icon: electricalIcon,
    },
    {
      id: "fuelSystem",
      name: "purchase:fuel_system",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_fuel_system_components",
      },
      icon: fuelSystemIcon,
    },
    {
      id: "frontSuspension",
      name: "purchase:front_suspension",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_front_suspension_components",
      },
      icon: suspensionIcon,
    },
    {
      id: "sealsAndGaskets",
      name: "purchase:seals_and_gaskets",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_seals_and_gaskets_components",
      },
      icon: sealsAndGasketsIcon,
    },
    {
      id: "airConditioner",
      name: "purchase:air_conditioner",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_air_conditioner_components",
      },
      icon: airConditioningIcon,
    },
    {
      id: "extraValueProtection",
      name: "purchase:extra_value_protection",
      coverages: ["LI"],
      details: {
        i18nkey: "purchase:essential_extra_value_protection_components",
      },
      icon: extraValueIcon,
    },
  ],
  [ExtendedWarrantyAmaRightRideDigital.id]: [
    {
      id: "engine",
      name: "purchase:engine",
      coverages: ["AA", "AB", "MA", "BB", "CC"],
      details: {
        i18nkey: "purchase:ama_engine_components",
      },
      icon: engineIcon,
    },
    {
      id: "turbocharger",
      name: "purchase:turbocharger",
      coverages: ["AA", "AB", "MA", "BB", "CC"],
      details: {
        i18nkey: "purchase:ama_turbocharger_components",
      },
      icon: turbochargerIcon,
    },
    {
      id: "transmission",
      name: "common:transmission",
      coverages: ["AA", "AB", "MA", "BB", "CC"],
      details: {
        i18nkey: "purchase:ama_transmission_components",
      },
      icon: transmissionIcon,
    },
    {
      id: "transferCase",
      name: "purchase:transfer_case",
      coverages: ["AA", "AB", "MA", "BB", "CC"],
      details: {
        i18nkey: "purchase:ama_transfer_case_components",
      },
      icon: transferCaseIcon,
    },
    {
      id: "driveAxle",
      name: "purchase:drive_axle",
      coverages: ["AA", "AB", "MA", "BB", "CC"],
      details: {
        i18nkey: "purchase:ama_drive_axle_components",
      },
      icon: driveAxleIcon,
    },
    {
      id: "steering",
      name: "purchase:steering",
      coverages: ["AA", "AB", "MA", "BB", "CC"],
      details: {
        i18nkey: "purchase:ama_steering_components",
      },
      icon: steeringIcon,
    },
    {
      id: "brakes",
      name: "purchase:brakes",
      coverages: ["AA", "AB", "MA", "BB", "CC"],
      details: {
        i18nkey: "purchase:ama_brakes_components",
      },
      icon: brakesIcon,
    },
    {
      id: "electrical",
      name: "purchase:electrical",
      coverages: ["AA", "AB", "MA", "BB", "CC"],
      details: {
        i18nkey: "purchase:ama_electrical_components",
      },
      icon: electricalIcon,
    },
    {
      id: "airConditioner",
      name: "purchase:air_conditioner",
      coverages: ["AA", "AB", "MA", "BB", "CC"],
      details: {
        i18nkey: "purchase:ama_ac_components",
      },
      icon: airConditioningIcon,
    },
    {
      id: "fuelSystem",
      name: "purchase:fuel_system",
      coverages: ["AA", "AB", "MA", "BB", "CC"],
      details: {
        i18nkey: "purchase:ama_fuel_system_components",
      },
      icon: fuelSystemIcon,
    },
    {
      id: "frontRearSuspensions",
      name: "purchase:front_rear_suspensions",
      coverages: ["AA", "AB", "MA", "BB", "CC"],
      details: {
        i18nkey: "purchase:ama_front_rear_suspension_components",
      },
      icon: suspensionIcon,
    },
    {
      id: "coolingSystem",
      name: "purchase:cooling_system",
      coverages: ["AA", "AB", "MA", "BB", "CC"],
      details: {
        i18nkey: "purchase:ama_cooling_components",
      },
      icon: coolingSystemIcon,
    },
    {
      id: "enhancedElectrical",
      name: "purchase:enhanced_electrical",
      coverages: ["AA", "AB", "MA", "BB", "CC"],
      details: {
        i18nkey: "purchase:ama_enhanced_electrical_components",
      },
      icon: enhancedElectricalIcon,
    },
  ],
};

export default warrantyComponentsByProgram;
