import { TranslationKeys } from "src/@types/i18next";
import DealTypeEnum from "@modules/admin/deals/enums/DealTypeEnum";
import { AftermarketSources } from "./AftermarketSource";

export type PurchaseFlow = "vehicle" | "aftermarket" | "financing" | "ico";
export type PurchaseStep = {
  id: number;
  flow: PurchaseFlow;
  nameTranslation: TranslationKeys["common"] | TranslationKeys["purchase"];
  alternateAdminNameTranslation?:
    | TranslationKeys["common"]
    | TranslationKeys["purchase"];
  order: number;
  href: string;
  isPointOfNoReturn: boolean;
  introHref?: string;
  guideTitle?: TranslationKeys["common"] | TranslationKeys["purchase"];
  guideDescription?: TranslationKeys["common"] | TranslationKeys["purchase"];
};

// Financing flow steps

// This is a placeholder step to represent consumer financing intro page.
export const consumerFinancingIntroStep: PurchaseStep = {
  id: 0,
  flow: "financing",
  nameTranslation: "common:introduction",
  order: 0,
  href: "/purchase/consumer/introduction",
  isPointOfNoReturn: false,
};

export const consumerFinancingDetailsStep: PurchaseStep = {
  id: 13,
  flow: "financing",
  nameTranslation: "common:vehicle_details",
  order: 1,
  href: "/purchase/consumer/vehicle-details",
  isPointOfNoReturn: false,
  introHref: "/purchase/consumer/introduction",
};

export const consumerFinancingPersonalInfoStep: PurchaseStep = {
  id: 14,
  flow: "financing",
  nameTranslation: "purchase:personal_info",
  order: 2,
  href: "/purchase/consumer/personal-info",
  isPointOfNoReturn: false,
};

export const consumerFinancingFinancingStep: PurchaseStep = {
  id: 15,
  flow: "financing",
  nameTranslation: "common:financing",
  order: 4,
  href: "/purchase/consumer/financing",
  isPointOfNoReturn: false,
};

export const consumerFinancingCoveragesStep: PurchaseStep = {
  id: 16,
  flow: "financing",
  nameTranslation: "purchase:coverages",
  order: 3,
  href: "/purchase/consumer/coverages",
  isPointOfNoReturn: false,
  introHref: "/purchase/consumer/coverages-intro",
};

export const consumerFinancingReviewStep: PurchaseStep = {
  id: 17,
  flow: "financing",
  nameTranslation: "purchase:review",
  order: 5,
  href: "/purchase/consumer/review",
  isPointOfNoReturn: false,
};

export const consumerFinancingUploadAndSignStep: PurchaseStep = {
  id: 18,
  flow: "financing",
  nameTranslation: "purchase:upload_and_sign",
  order: 6,
  href: "/purchase/consumer/checklist",
  isPointOfNoReturn: false,
};

// Vehicle flow steps

export const tradeInStep: PurchaseStep = {
  id: 1,
  flow: "vehicle",
  nameTranslation: "purchase:trade_in",
  order: 1,
  href: "/purchase/trade-in",
  isPointOfNoReturn: false,
};

export const personalInformationStep: PurchaseStep = {
  id: 2,
  flow: "vehicle",
  nameTranslation: "purchase:personal_info",
  order: 2,
  href: "/purchase/personal-information",
  isPointOfNoReturn: false,
};

export const deliveryStep: PurchaseStep = {
  id: 3,
  flow: "vehicle",
  nameTranslation: "purchase:delivery",
  order: 3,
  href: "/purchase/delivery",
  isPointOfNoReturn: false,
};

export const financingStep: PurchaseStep = {
  id: 4,
  flow: "vehicle",
  nameTranslation: "common:financing",
  order: 4,
  href: "/purchase/financing",
  isPointOfNoReturn: false,
};

export const warrantyStep: PurchaseStep = {
  id: 5,
  flow: "vehicle",
  nameTranslation: "purchase:warranty",
  order: 5,
  href: "/purchase/warranty",
  isPointOfNoReturn: false,
};

export const reviewStep: PurchaseStep = {
  id: 6,
  flow: "vehicle",
  nameTranslation: "purchase:review",
  order: 6,
  href: "/purchase/review",
  isPointOfNoReturn: false,
};

// Aftermarket flow steps

export const aftermarketDetailsStep: PurchaseStep = {
  id: 8,
  flow: "aftermarket",
  nameTranslation: "common:details",
  order: 1,
  href: "/purchase/coverage/details",
  isPointOfNoReturn: false,
  introHref: "/purchase/coverage/introduction",
  guideTitle: "common:enter_your_details",
  guideDescription:
    "purchase:provide_information_about_your_vehicle_warranty_registration",
};

export const aftermarketCoverageStep: PurchaseStep = {
  id: 7,
  flow: "aftermarket",
  nameTranslation: "purchase:confirm_coverage",
  order: 2,
  href: "/purchase/coverage/configure",
  isPointOfNoReturn: false,
  guideTitle: "purchase:select_and_confirm_coverage",
  guideDescription: "purchase:tailor_warranty_offerings_by_coverage_type",
};

export const aftermarketContractStep: PurchaseStep = {
  id: 9,
  flow: "aftermarket",
  nameTranslation: "purchase:contract",
  order: 3,
  href: "/purchase/coverage/contract",
  isPointOfNoReturn: false,
  guideTitle: "purchase:e_sign_documents",
  guideDescription: "purchase:finalize_coverage_details_by_esigning_contracts",
};

export const aftermarketPaymentStep: PurchaseStep = {
  id: 11,
  flow: "aftermarket",
  nameTranslation: "purchase:payment",
  order: 4,
  href: "/purchase/coverage/payment",
  isPointOfNoReturn: false,
  guideTitle: "purchase:make_a_payment",
  guideDescription: "purchase:make_payment_using_preferred_card",
};

export const aftermarketCompleteStep: PurchaseStep = {
  id: 12,
  flow: "aftermarket",
  nameTranslation: "common:thank_you",
  alternateAdminNameTranslation: "common:complete",
  order: 5,
  href: "/purchase/coverage/complete",
  isPointOfNoReturn: true,
  guideTitle: "purchase:provide_safety_inspection",
  guideDescription: "purchase:upload_copy_of_safety_to_prevent_inspection",
};

// Instant Cash Offer flow steps

export const icoLandingPageStep: PurchaseStep = {
  id: 0,
  flow: "ico",
  nameTranslation: "common:landing_page",
  order: 0,
  href: "/purchase/offer",
  isPointOfNoReturn: false,
};

export const icoPersonalDetailsStep: PurchaseStep = {
  id: 19,
  flow: "ico",
  nameTranslation: "common:details",
  alternateAdminNameTranslation: "common:personal_details",
  order: 1,
  href: "/purchase/offer/personal-details",
  isPointOfNoReturn: false,
};

export const icoOfferDetailStep: PurchaseStep = {
  id: 20,
  flow: "ico",
  nameTranslation: "common:details",
  alternateAdminNameTranslation: "purchase:offer_details",
  order: 2,
  href: "/purchase/offer/offer-detail",
  isPointOfNoReturn: false,
};

const allPurchaseSteps = {
  vehicle: [
    tradeInStep,
    personalInformationStep,
    deliveryStep,
    financingStep,
    warrantyStep,
    reviewStep,
  ].sort((a, b) => a.order - b.order),
  aftermarket: [
    aftermarketDetailsStep,
    aftermarketCoverageStep,
    aftermarketContractStep,
    aftermarketPaymentStep,
    aftermarketCompleteStep,
  ].sort((a, b) => a.order - b.order),
  financing: [
    consumerFinancingDetailsStep,
    consumerFinancingPersonalInfoStep,
    consumerFinancingCoveragesStep,
    consumerFinancingFinancingStep,
    consumerFinancingReviewStep,
    consumerFinancingUploadAndSignStep,
  ].sort((a, b) => a.order - b.order),
  ico: [icoPersonalDetailsStep, icoOfferDetailStep].sort(
    (a, b) => a.order - b.order
  ),
};

export const getAllPurchaseStepsForFlow = (flow: PurchaseFlow) =>
  allPurchaseSteps[flow];

export const getPreviousStep = (
  flow: PurchaseFlow,
  currentStep: PurchaseStep
) => {
  const steps = getAllPurchaseStepsForFlow(flow);
  const index = steps.findIndex((s) => s.id === currentStep.id);

  if (index === 0 || index === -1) {
    return undefined;
  }

  return steps[index - 1];
};

export const getNextStep = (flow: PurchaseFlow, currentStep: PurchaseStep) => {
  const steps = getAllPurchaseStepsForFlow(flow);
  const index = steps.findIndex((s) => s.id === currentStep.id);
  if (index === steps.length - 1 || index === -1) {
    return undefined;
  }
  return steps[index + 1];
};

export const purchaseLastStep: Record<PurchaseFlow, PurchaseStep> = {
  aftermarket: aftermarketCompleteStep,
  ico: icoOfferDetailStep,
  financing: consumerFinancingUploadAndSignStep,
  vehicle: reviewStep,
};

export const getStepById = (flow: PurchaseFlow, stepId: number) => {
  const step = getAllPurchaseStepsForFlow(flow).find((s) => s.id === stepId);

  return step;
};

export const getStepPathname = (step: PurchaseStep, intro = false) =>
  intro ? step.introHref || step.href : step.href;

export const getFirstStep = (flow: PurchaseFlow) =>
  getAllPurchaseStepsForFlow(flow)[0];

export const isStepAccessible = (
  currentStep: PurchaseStep,
  accessedStep: PurchaseStep,
  purchaseHasOverwriteCandidate = false
) => {
  /**
   * edge case:
   * *see this method usage on CoveragePurchaseStepAuthGuard.tsx*
   *
   * when user completed a purchase, logout, starts a new purchase and log back in,
   * at this point, they have overwrite candidate
   *
   * we want to stay at the current step and show resume modal
   * rather than redirecting them to complete step
   */

  if (currentStep.isPointOfNoReturn && !purchaseHasOverwriteCandidate) {
    return currentStep === accessedStep;
  }

  return accessedStep.order <= currentStep.order;
};

export const stepIsBeforeSigning = (step: PurchaseStep) =>
  step.flow === "aftermarket" && step.order < aftermarketContractStep.order;

export const isValidAftermarketSource = (source: string | undefined) =>
  source &&
  AftermarketSources.find(
    (s) => s.name.toLowerCase() === source.toLowerCase()
  ) !== undefined;

export const getFlowByDealType = (dealTypeId: DealTypeEnum): PurchaseFlow => {
  switch (dealTypeId) {
    case DealTypeEnum.Aftermarket:
      return "aftermarket";
    case DealTypeEnum.BuyIn:
      return "ico";
    case DealTypeEnum.C2CFinancing:
      return "financing";
    case DealTypeEnum.Cash:
    case DealTypeEnum.Finance:
    case DealTypeEnum.Lease:
      return "vehicle";
    default: {
      const exhaustiveCheck: never = dealTypeId;
      throw new Error(`No purchase flow found for: ${exhaustiveCheck}`);
    }
  }
};
