import { TranslationKeys } from "src/@types/i18next";

export type IACoverageType = {
  abbr: string;
  i18nKey: TranslationKeys["common"];
};

export const basicCoverage: IACoverageType = {
  abbr: "BA",
  i18nKey: "common:ew_coverage_type_basic",
};

export const prefferedCoverage: IACoverageType = {
  abbr: "PR",
  i18nKey: "common:ew_coverage_type_preferred",
};

export const classicCoverage: IACoverageType = {
  abbr: "CL",
  i18nKey: "common:ew_coverage_type_classic",
};

export const superiorCoverage: IACoverageType = {
  abbr: "SU",
  i18nKey: "common:ew_coverage_type_superior",
};

export const superiorWrapCoverage: IACoverageType = {
  abbr: "SW",
  i18nKey: "common:ew_coverage_type_superior_wrap",
};

export const limitedPowertrain: IACoverageType = {
  abbr: "LI",
  i18nKey: "common:ew_coverage_type_essential",
};

export const oneThousandTwoHundredFifty: IACoverageType = {
  abbr: "AA",
  i18nKey: "common:ew_coverage_type_ama",
};

export const twoThousandFiveHundred: IACoverageType = {
  abbr: "AB",
  i18nKey: "common:ew_coverage_type_ama",
};

export const fiveThousand: IACoverageType = {
  abbr: "MA",
  i18nKey: "common:ew_coverage_type_ama",
};

export const sevenThousandFiveHundred: IACoverageType = {
  abbr: "BB",
  i18nKey: "common:ew_coverage_type_ama",
};

export const tenThousand: IACoverageType = {
  abbr: "CC",
  i18nKey: "common:ew_coverage_type_ama",
};

export const IACoverageTypes: IACoverageType[] = [
  basicCoverage,
  prefferedCoverage,
  classicCoverage,
  limitedPowertrain,
  superiorCoverage,
  superiorWrapCoverage,
  oneThousandTwoHundredFifty,
  twoThousandFiveHundred,
  fiveThousand,
  sevenThousandFiveHundred,
  tenThousand,
];

export function getCoverageByAbbr(abbr: string): IACoverageType | undefined {
  return IACoverageTypes.find((coverage) => coverage.abbr === abbr);
}
