import { Instant } from "@js-joda/core";
import HTTPErrorWithProblemDetails from "@modules/api/HTTPErrorWithProblemDetails";

export const coverageErrorReasons = {
  noCoverages: "No coverages",
  maintenance: "Maintenance",
  mfgWarrantyDateInvalid:
    "EW: MFG warranty start date must be less than or equal to Delivery date",
  invalidVin: "Invalid VIN",
  noVehicleFound: "No vehicle found",
  vehicleCannotBeRated: "Vehicle Cannot Be Rated",
};

type CoverageError = {
  reason: string;
  estimatedHealthyAt: Instant | null;
};

function isDownForMaintenance(error: HTTPErrorWithProblemDetails) {
  return (
    error.response.status === 503 &&
    error.problemDetails.detail === "Down for maintenance"
  );
}

function getEstimatedHealthyAt(error: HTTPErrorWithProblemDetails) {
  if (typeof error.problemDetails.returningEstimate === "string") {
    return Instant.parse(error.problemDetails.returningEstimate);
  }

  return null;
}

export function getCoveragesError(error: unknown): CoverageError {
  let reason = coverageErrorReasons.noCoverages;
  let estimatedHealthyAt: Instant | null = null;

  if (error instanceof HTTPErrorWithProblemDetails) {
    reason = isDownForMaintenance(error)
      ? coverageErrorReasons.maintenance
      : reason;
    estimatedHealthyAt = getEstimatedHealthyAt(error);
  } else if (error instanceof Error) {
    reason = error.message;
  }

  return {
    reason,
    estimatedHealthyAt,
  };
}
